<template>
  <v-container fluid class="text-center">
    <v-breadcrumbs :items="path" large></v-breadcrumbs>
    <v-toolbar-title dense dark class="title text-left mt-5 mx-5">
      <v-row>
        <v-col cols="12" md="3" xs="12"> Usuarios </v-col>
        <v-col cols="12" md="9" xs="12" class="text-end" style="white-space: normal">
          <span class="caption grey--text darken-2" style="font-family: 'Quicksand', sans-serif !important"
            >Desde esta pantalla es posible administrar la información de los usuarios, su contraseña y roles. Es
            importante colocar contraseñas seguras.</span
          >
        </v-col>
      </v-row>
    </v-toolbar-title>
    <v-card class="elevation-5 mt-5 mx-5">
      <v-card-title style="gap: 20px">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
          style="max-width: 400px"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          small
          :loading="loading"
          elevation="1"
          color="green"
          class="mx-2 custom-button white--text"
          @click="dialogs.new = true"
        >
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items-per-page="10"
        item-key="id"
        class="elevation-1"
        dense
      >
        <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="view(item)"
                >mdi-pencil</v-icon
              ></template
            ><span>Editar</span></v-tooltip
          >
          <!--<v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'companies')">mdi-domain</v-icon></template><span>Asignar empresas</span></v-tooltip>-->
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="view(item, 'roles')"
                >mdi-shield-account</v-icon
              ></template
            ><span>Actualizar rol</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="updateStatus(item.id_user)"
                >mdi-account-reactivate</v-icon
              ></template
            ><span>Activar / Desactivar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="warning"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="viewReset(item)"
                >mdi-key</v-icon
              ></template
            ><span>Actualizar contraseña</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon
                class="mr-2"
                size="22"
                color="error"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="loading"
                @click="remove(item.id_user)"
                >mdi-delete</v-icon
              ></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-btn v-if="item.active" x-small elevation="0" color="green" class="mx-1" rounded dark>Activado</v-btn>
          <v-btn v-else x-small elevation="0" color="error" class="mx-1" rounded dark>Desactivado</v-btn>
        </template>
        <template v-slot:[`item.users_roles`]="{ item }">
          <template v-for="(rol, idx) in item.users_roles">
            <v-btn
              v-if="rol.role.role === 'admin'"
              x-small
              elevation="0"
              color="error"
              class="mx-1"
              rounded
              dark
              :key="idx"
              >{{ rol.role.role }}</v-btn
            >
            <v-btn v-else x-small elevation="0" color="blue-grey" class="mx-1" rounded dark :key="idx">{{
              rol.role.role
            }}</v-btn>
          </template>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Nuevo usuario</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.username"
                    label="Correo"
                    :rules="rules.username"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.password"
                    label="Contraseña"
                    :type="show ? 'text' : 'password'"
                    :rules="rules.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.name"
                    label="Nombre"
                    :rules="rules.name"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.new.lastname"
                    label="Apellido Paterno"
                    :rules="rules.lastname"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.new.mother_lastname"
                    label="Apellido Materno"
                    :rules="rules.mother_lastname"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Actualizar usuario</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.username"
                    label="Correo"
                    disabled
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.password"
                    label="Contraseña"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    disabled
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.name"
                    label="name"
                    :rules="rules.name"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.lastname"
                    label="Apellido Paterno"
                    :rules="rules.lastname"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.mother_lastname"
                    label="Apellido Materno"
                    :rules="rules.mother_lastname"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.companies" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Asignar empresas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="forms.update.companies"
                    :items="catalogues.companies"
                    item-text="name"
                    item-value="id_company"
                    label="Empresas"
                    dense
                    outlined
                    multiple
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.roles" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Actualizar rol</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-select
                    v-model="forms.update.roles"
                    :items="catalogues.roles"
                    item-text="role"
                    item-value="id_role"
                    label="Rol"
                    dense
                    outlined
                    multiple
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.reset" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Actualizar contraseña</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="resetPassword()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formReset" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.reset.username"
                    label="Correo"
                    disabled
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.reset.password"
                    label="Contraseña"
                    :type="show ? 'text' : 'password'"
                    :rules="rules.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="forms.reset.confirmPassword"
                    label="Confirmar contraseña"
                    :type="show ? 'text' : 'password'"
                    :rules="rules.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el usuario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from "@/utils/services";
import _ from "lodash";

export default {
  name: "user",
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    search: "",
    path: [
      {
        text: "SMS",
        disabled: false,
        href: "home",
      },
      {
        text: "Usuarios",
        disabled: false,
        href: "",
      },
    ],
    headers: [
      { text: "Correo", align: "left", sortable: false, value: "username" },
      { text: "Nombre", align: "left", sortable: false, value: "users_details[0].name" },
      { text: "Apellido Paterno", align: "left", sortable: false, value: "users_details[0].lastname" },
      { text: "Apellido Materno", align: "left", sortable: false, value: "users_details[0].mother_lastname" },
      // { text: 'Empresas asignadas', align: 'left', sortable: false, value: 'users_companies.length' },
      { text: "Estatus", align: "left", sortable: false, value: "active" },
      { text: "Rol", align: "left", sortable: false, value: "users_roles" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    selected: [],
    deleted: null,
    show: false,
    catalogues: {
      companies: [],
      roles: [],
    },
    forms: {
      new: {
        username: "",
        password: "",
        name: "",
        lastname: "",
        mother_lastname: "",
      },
      update: {
        id_user: "",
        username: "",
        password: "",
        name: "",
        lastname: "",
        mother_lastname: "",
      },
      reset: {
        id_user: "",
        password: "",
        confirmPassword: "",
      },
    },
    rules: {
      username: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length <= 320) || "El campo excede la longitud máxima",
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "El campo es inválido",
      ],
      password: [
        (v) => !!v || "El campo es requerido",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número",
        (v) => (v && v.length <= 60) || "La contraseña excede la longitud máxima",
        (v) =>
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) ||
          "La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número",
      ],
      name: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length <= 100) || "El campo excede la longitud máxima",
      ],
      lastname: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length <= 100) || "El campo excede la longitud máxima",
      ],
      mother_lastname: [(v) => _.isNil(v) || (!_.isNil(v) && v.length <= 100) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      companies: false,
      roles: false,
      reset: false,
      remove: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      const company = await this.axios.get(services.routes.company);
      if (company.data.data) {
        this.catalogues.companies = company.data.data;
      }
      const role = await this.axios.get(services.routes.role);
      if (role.data.data) {
        this.catalogues.roles = role.data.data;
      }
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = "";
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update || this.dialogs.companies || this.dialogs.roles) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          this.forms.update[key] = "";
        }
        delete this.forms.update.companies;
        delete this.forms.update.roles;
        this.dialogs.update = false;
        this.dialogs.companies = false;
        this.dialogs.roles = false;
      } else if (this.dialogs.reset) {
        this.$refs.formReset.reset();
        for (let key in this.forms.reset) {
          this.forms.reset[key] = "";
        }
        this.dialogs.reset = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
      this.show = false;
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Usuario creado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    view(data, type) {
      this.forms.update = {
        id_user: data.id_user,
        username: data.username,
        password: "********************",
        name: data.users_details[0].name,
        lastname: data.users_details[0].lastname,
        mother_lastname: data.users_details[0].mother_lastname,
      };
      if (type === "companies") {
        this.forms.update.companies = [];
        for (let i = 0; i < data.users_companies.length; i++) {
          this.forms.update.companies.push(data.users_companies[i].company.id_company);
        }
        this.dialogs.companies = true;
      } else if (type === "roles") {
        this.forms.update.roles = [];
        if (data.users_roles.length) {
          for (let rol of data.users_roles) {
            this.forms.update.roles.push(rol.role.id_role);
          }
        }
        this.dialogs.roles = true;
      } else {
        this.dialogs.update = true;
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Usuario actualizado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    updateStatus(id) {
      this.loading = true;
      this.axios
        .put(services.routes.user + "/updateStatus", { data: { user: id } })
        .then((response) => {
          this.cancel();
          this.refresh();
          this.success = true;
          this.msgSuccess = "Usuario actualizado exitosamente";
        })
        .catch((error) => {
          this.error = true;
          this.msgError = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewReset(data) {
      this.forms.reset = {
        id_user: data.id_user,
        username: data.username,
        password: "",
      };
      this.dialogs.reset = true;
    },
    resetPassword() {
      if (this.$refs.formReset.validate()) {
        if (this.forms.reset.password === this.forms.reset.confirmPassword) {
          this.loading = true;
          let data = JSON.parse(JSON.stringify(this.forms.reset));
          delete data.confirmPassword;
          this.axios
            .put(services.routes.user + "/password", { data })
            .then((response) => {
              this.cancel();
              this.refresh();
              this.success = true;
              this.msgSuccess = "Contraseña actualizada exitosamente";
            })
            .catch((error) => {
              this.error = true;
              this.msgError = error.response.data.message || "Servicio no disponible";
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.error = true;
          this.msgError = "Las contraseñas no coinciden";
        }
      } else {
        this.error = true;
        this.msgError = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.user, { data: { data: { id_user: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.success = true;
            this.msgSuccess = "Usuario eliminado exitosamente";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-family: "Quicksand", sans-serif !important;
  font-size: 2rem !important;
  line-height: 1.2;
}
</style>
