const host = process.env.VUE_APP_API_SERVER

const routes = {
  dashboard: host + '/api/admin/dashboard',
  user: host + '/api/admin/user',
  role: host + '/api/admin/role',
  company: host + '/api/admin/company',
  provider: host + '/api/admin/provider/setting',
  category: host + '/api/admin/category',
  subcategory: host + '/api/admin/subcategory',
  label: host + '/api/admin/label',
  campaign: host + '/api/admin/campaign',
}

export default  {
  routes 
}
